<kadu-form
  elClass="d-flex justify-content-center flex-column align-items-start h-100 px-sm-5 p-3"
  [controls]="formControls"
>
  <ng-template kaduTemplate let-formGroup>
    <ng-container [formGroup]="formGroup">
      <div class="d-flex flex-column w-100 align-items-start">
        <label class="p2 my-2">
          {{ "dashboard.signupSurvey.foundAboutBrendly" | transloco }}
        </label>
        <kadu-radiobutton
          [optionValue]="option.value"
          [label]="option.label"
          *ngFor="let option of options"
          formControlName="refererOrigin"
          class="p3"
          elClass="d-flex align-items-baseline"
          name="refererOrigin"
        ></kadu-radiobutton>

        <div style="min-height: 45px" class="w-100">
          <div *ngIf="formGroup.get('refererOriginFreeEntry')">
            <kadu-input
              formControlName="refererOriginFreeEntry"
              elClass="p3 p-2 mt-2"
              [placeholder]="'dashboard.signupSurvey.addDesc' | transloco"
              #refererOriginFreeEntry
            ></kadu-input>
            <app-form-errors
              [formElement]="refererOriginFreeEntry"
              [control]="formGroup.get('refererOriginFreeEntry')"
            >
            </app-form-errors>
          </div>
        </div>
      </div>

      <div
        class="d-flex gap-2 col-12 col-sm-4 col-lg-3 justify-content-end w-100"
      >
        <kadu-button
          (clickHandler)="goToStep('prev')"
          elClass="btn btn-xs btn-cancel w-100"
          type="button"
        >
          {{ "common.back" | transloco }}
        </kadu-button>

        <kadu-button
          (clickHandler)="goToStep('next')"
          elClass="btn btn-xs btn-submit w-100"
        >
          {{ "common.save" | transloco }}
        </kadu-button>
      </div>
    </ng-container>
  </ng-template>
</kadu-form>
