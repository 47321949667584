import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { PopupService } from 'src/app/_services/popup/popup.service';
import { UserService } from 'src/app/dashboard/_services/user/user.service';

@Component({
  selector: 'app-email-verification-success',
  templateUrl: './email-verification-success.component.html',
  styleUrls: ['./email-verification-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailVerificationSuccessComponent implements OnInit {
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private popupService: PopupService,
    private translateService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.userService.verifyEmail(this.route.snapshot.params.userId).subscribe(
      () => {},
      () => {
        this.popupService.fire({
          titleText: this.translateService.translate('common.error'),
          type: 'error',
          confirmButtonColor: '#f3364c',
        });
      },
    );
  }
}
