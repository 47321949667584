<div class="d-flex align-items-center" style="min-height: calc(100vh - 60px)">
  <main class="d-flex w-100 container-xxl">
    <section
      class="form w-100 d-flex justify-content-center align-items-center"
    >
      <div class="wrapper mt-lg-0 py-5">
        <p class="p2 fw-600">
          {{ "dashboard.emailVerification.title" | transloco }}
        </p>

        <p class="p2 mb-0">
          {{ "dashboard.emailVerification.desc" | transloco }}
        </p>
      </div>
    </section>
  </main>
</div>
