import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { KaduFormComponent } from '@kadung/kadung/kadu-form';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-signup-survey',
  styleUrls: ['./signup-survey.component.scss'],
  templateUrl: './signup-survey.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignupSurveyComponent implements OnDestroy {
  @Input() userForRegistration$: BehaviorSubject<any>;
  @ViewChild(KaduFormComponent, { static: false })
  formComponent: KaduFormComponent;

  @Output('previousStep') previousStep = new EventEmitter<any>();
  @Output('nextStep') nextStep = new EventEmitter<any>();

  formControls = {
    customerSegment: { state: 'designer' },
  };

  options = [
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.designer',
      ),
      value: 'designer',
    },
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.marketer',
      ),
      value: 'marketer',
    },
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.designerAndMarketer',
      ),
      value: 'designerAndMarketer',
    },
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.influencer',
      ),
      value: 'influencer',
    },
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.firm',
      ),
      value: 'firm',
    },
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.other',
      ),
      value: 'other',
    },
  ];

  subs = new SubSink();
  constructor(private translateService: TranslocoService) {}

  ngAfterViewInit(): void {
    this.subs.add(
      this.formComponent.formGroup
        .get('customerSegment')
        .valueChanges.subscribe((value) => {
          if (value === 'other') {
            this.formComponent.addControlToForm('customerSegmentFreeEntry', {
              state: '',
              validators: [Validators.required],
            });
          } else {
            this.formComponent.removeControlFromForm(
              'customerSegmentFreeEntry',
            );
          }
        }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    const userForRegistration = changes.userForRegistration$.currentValue.value;

    if (userForRegistration.customerSegment) {
      setTimeout(() => {
        if (userForRegistration.customerSegmentType === 'other') {
          this.formComponent.formGroup.patchValue({
            customerSegment: userForRegistration.customerSegmentType,
            customerSegmentFreeEntry: userForRegistration.customerSegment,
          });
        } else {
          this.formComponent.formGroup.patchValue({
            customerSegment: userForRegistration.customerSegment,
          });
        }
      });
    }
  }

  goToStep(mode: 'prev' | 'next') {
    this.formComponent.submit();

    if (this.formComponent.formGroup.valid) {
      this.userForRegistration$.next({
        ...this.userForRegistration$.value,
        customerSegmentType: this.formComponent.formGroup.value.customerSegment,
        customerSegment:
          this.formComponent.formGroup.value.customerSegmentFreeEntry ||
          this.formComponent.formGroup.value.customerSegment,
      });

      if (mode === 'prev') {
        this.previousStep.emit();
      } else {
        this.nextStep.emit();
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
