import { registerLocaleData } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import en from '@angular/common/locales/en';
import { AppComponent } from './app.component';
import { HeaderComponent } from './_components/header/header.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from 'primeng/api';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AuthModule } from './auth/auth.module';
import { TranslocoRootModule } from './transloco-root.module';
import { KaduButtonModule } from '@kadung/kadung/kadu-button';
import { KaduSelectModule } from '@kadung/kadung/kadu-select';
import { KaduOverlayModule } from '@kadung/kadung/kadu-overlay';
import { KaduListboxModule } from '@kadung/kadung/kadu-listbox';
import { KaduMenuModule } from '@kadung/kadung/kadu-menu';
import { KaduLetModule } from '@kadung/kadung/kadu-let';
import { KaduSlideMenuModule } from '@kadung/kadung/kadu-slide-menu';
import {
  KaduConfirmationModule,
  KaduConfirmDialogModule,
} from '@kadung/kadung/kadu-confirm-dialog';
import { LoadingInterceptorService } from './_interceptors/loading-interceptor.service';
import { JwtRenewInterceptorService } from './_interceptors/jwt-renew-interceptor.service';
import { JwtAuthorizationInterceptorService } from './_interceptors/jwt-authorization-interceptor.service';
import { ErrorHandlingInterceptorService } from './_interceptors/error-handling-interceptor.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DtoTransformInterceptor } from './_interceptors/dto-transform.interceptor';
import { LanguageInterceptor } from './_interceptors/language.interceptor';
import { TimezoneInterceptor } from './_interceptors/timezone.interceptor';
import { ClearInterceptor } from './_interceptors/clear.interceptor';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { KaduAnchorModule } from '@kadung/kadung/kadu-anchor';
import { KaduLoaderModule } from '@kadung/kadung/kadu-loader';
import localeSr from '@angular/common/locales/sr';
import { KaduTableModule } from '@kadung/kadung/kadu-table';
import { KaduTooltipModule } from '@kadung/kadung/kadu-tooltip';
import { EmailVerificationSuccessComponent } from './_components/email-verification-success/email-verification-success.component';

registerLocaleData(en);
registerLocaleData(localeSr, 'sr-RS');

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    EmailVerificationSuccessComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    AuthModule,
    TranslocoRootModule,
    KaduButtonModule,
    KaduSelectModule,
    KaduOverlayModule,
    KaduListboxModule,
    KaduMenuModule,
    KaduSlideMenuModule,
    KaduLetModule,
    KaduConfirmationModule,
    KaduConfirmDialogModule,
    KaduAnchorModule,
    KaduTooltipModule,
    KaduLoaderModule,
    KaduTableModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtRenewInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtAuthorizationInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlingInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DtoTransformInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimezoneInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClearInterceptor,
      multi: true,
    },
    GoogleTagManagerService,
  ],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
