<kadu-form
  elClass="d-flex justify-content-center flex-column align-items-start h-100 px-sm-5 p-3"
  [controls]="formControls"
>
  <ng-template kaduTemplate let-formGroup>
    <ng-container [formGroup]="formGroup">
      <div class="d-flex flex-column w-50 align-items-start">
        <label class="p2 mb-2">
          {{ "dashboard.signupSurvey.describeYourself" | transloco }}
        </label>
        <kadu-radiobutton
          [optionValue]="option.value"
          [label]="option.label"
          *ngFor="let option of options"
          formControlName="customerSegment"
          class="p3"
          elClass="d-flex align-items-baseline"
          name="customer"
        ></kadu-radiobutton>

        <div style="min-height: 45px" class="w-100">
          <div *ngIf="formGroup.get('customerSegmentFreeEntry')">
            <kadu-input
              formControlName="customerSegmentFreeEntry"
              elClass="p3 p-2 mt-2"
              [placeholder]="'dashboard.signupSurvey.addDesc' | transloco"
              #customerSegmentFreeEntry
            ></kadu-input>

            <app-form-errors
              [formElement]="customerSegmentFreeEntry"
              [control]="formGroup.get('customerSegmentFreeEntry')"
            >
            </app-form-errors>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-end justify-content-end w-100 gap-2">
        <kadu-button
          (clickHandler)="goToStep('prev')"
          elClass="btn btn-xs btn-cancel w-100"
          type="button"
        >
          {{ "common.back" | transloco }}
        </kadu-button>

        <kadu-button
          (clickHandler)="goToStep('next')"
          elClass="btn btn-xs btn-submit"
        >
          {{ "common.save" | transloco }}
        </kadu-button>
      </div>
    </ng-container>
  </ng-template>
</kadu-form>
