import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslocoModule } from '@ngneat/transloco';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SharedModule } from '../shared/shared.module';
import { SignupSurveyComponent } from './_components/signup-survey/signup-survey.component';
import { ForgotPasswordComponent } from './_pages/forgot-password/forgot-password.component';
import { LoginComponent } from './_pages/login/login.component';
import { SignupComponent } from './_pages/signup/signup.component';
import { AuthRoutingModule } from './auth-routing.module';

import { KaduButtonModule } from '@kadung/kadung/kadu-button';
import { KaduCheckboxModule } from '@kadung/kadung/kadu-checkbox';
import { KaduFormModule } from '@kadung/kadung/kadu-form';
import { KaduInputModule } from '@kadung/kadung/kadu-input';
import { KaduRadioButtonModule } from '@kadung/kadung/kadu-radiobutton';
import { KaduSelectModule } from '@kadung/kadung/kadu-select';
import { KaduTemplateModule } from '@kadung/kadung/kadu-template';
import { FoundBrendlyComponent } from './_components/found-brendly/found-brendly.component';
import { KaduStepsModule } from '@kadung/kadung/kadu-steps';
import { KaduLetModule } from '@kadung/kadung/kadu-let';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ProgressSpinnerModule,
    TranslocoModule,
    KaduFormModule,
    KaduTemplateModule,
    KaduInputModule,
    KaduButtonModule,
    KaduCheckboxModule,
    SharedModule,
    KaduSelectModule,
    KaduRadioButtonModule,
    KaduStepsModule,
    KaduLetModule,
  ],
  declarations: [
    LoginComponent,
    SignupComponent,
    SignupSurveyComponent,
    ForgotPasswordComponent,
    FoundBrendlyComponent,
  ],
})
export class AuthModule {}
