import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { fabric } from 'fabric';
import 'fabric-customise-controls';
import FontFaceObserver from 'fontfaceobserver';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, skip, take } from 'rxjs/operators';
import { SKIP_INTERCEPTORS } from 'src/app/_interceptors/skip-interceptors';
import { LoaderService } from 'src/app/_services/loader/loader.service';
import { SubSink } from 'subsink';
import { BrendlyImage } from '../gallery/_models/image';
import { createBackgroundColor, firstDivisibleByBiggerThan } from '../util';
import { Color } from './_models/color';
import { Image } from './_models/image';
import * as moment from 'moment';
import { BoxPaintOccupation } from './_models/box-paint-occupation';
import { environment } from 'src/environments/environment';
import { DuloImageEditorService } from './_services/dulo-image-editor.service';

@Component({
  selector: 'app-dulo-image-editor',
  templateUrl: './dulo-image-editor.component.html',
  styleUrls: ['./dulo-image-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuloImageEditorComponent implements OnChanges, OnDestroy {
  @Input() image: Image;
  @Input() objects = [];
  @Input() defaultColorId: string;
  @Input() colors: Color[];
  @Input() chosenGalleryImage;
  @Input() backgroundColor: string;

  @ViewChild('canvasWrapper', { static: true }) canvasWrapper: ElementRef;
  @ViewChild('canvas', { static: true })
  canvasEl: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvasWithTransparentObjects', { static: true })
  canvasWithTransparentObjectsEl: ElementRef<HTMLCanvasElement>;
  @ViewChild('debugCanvas', { static: false })
  debugCanvasEl: ElementRef<HTMLCanvasElement>;

  screen: 'mobile' | 'desktop' = window.innerWidth > 991 ? 'desktop' : 'mobile';
  canvasProperties$ = new BehaviorSubject<any>(null);

  subs = new SubSink();
  NODE_CANVAS_SIZE = 4096;
  selected$ = new BehaviorSubject<boolean>(false);
  imageSelected = false;
  textSelected = false;
  isImageJPG = false;
  isCanvasChanged = false;

  devEnvironment = false;
  // devEnvironment =
  //   environment.environment === 'dev' || environment.environment === 'stage';
  // devEnvironment = environment.environment === 'dev';

  backgroundImage: any;

  canvas: any;
  canvasWithTransparentObjects: fabric.Canvas;
  debugCanvas;
  rect: any;
  rectInitialized$ = new BehaviorSubject<boolean>(false);
  activeObject: any;
  activeObjectOncanvasWithTransparentObjects: any;
  filterMap = {};
  filterIndexMap = {};
  verticalLineVisible: boolean;
  horizontalLineVisible: boolean;
  @Output() canvasChanged = new EventEmitter<boolean>();
  @Output() objectsLoaded = new EventEmitter<boolean>();
  loadedObjects = new BehaviorSubject<any>([]);
  loadedObjects$ = this.loadedObjects.asObservable();
  areObjectsLoaded = false;
  unloadingObjects = false;
  loadingObjects = false;
  canvasPadding = 50;
  visibleEditingBox: any;
  currentImageDPI$ = new BehaviorSubject<number>(0);
  currentImageDPIState: 'Dobar' | 'Prosečan' | 'Loš';

  visibleEdditingBoxPaintOccupation$ = new BehaviorSubject<BoxPaintOccupation>(
    null,
  );

  calculateVisibleEdditingBoxPaintSubject = new Subject();

  @HostListener('window:resize', [])
  setScreen(): void {
    this.screen = window.innerWidth > 991 ? 'desktop' : 'mobile';
    if (this.canvas) {
      this.onResize();
    }
  }

  createColor = createBackgroundColor;

  worker = new Worker(
    new URL(
      './editing-box-paint-occupation-calculator.worker',
      import.meta.url,
    ),
  );

  constructor(
    private loaderService: LoaderService,
    private http: HttpClient,
    private duloImageEditorService: DuloImageEditorService,
  ) {}

  ngOnInit(): void {
    // 2048 is used because it is fabric default
    fabric.textureSize = 2048;
    fabric.filterBackend = null;

    this.subs.add(
      this.calculateVisibleEdditingBoxPaintSubject
        .pipe(debounceTime(42 * 1.5))
        .subscribe(() => {
          if (Object.keys(this.canvas.vptCoords).length === 0) return;
          this.canvasWithTransparentObjects.renderAll();
          this.calculateVisibleEdditingBoxPaintOccupation();
        }),
    );

    this.worker.onmessage = ({ data }): void => {
      if (this.devEnvironment) {
        const debugContext = this.debugCanvasEl.nativeElement.getContext('2d');
        debugContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
        debugContext.fillStyle = 'red';

        data.differentPixels.forEach((sp) => {
          debugContext.fillRect(
            sp.x,
            sp.y,
            this.canvas.getRetinaScaling(),
            this.canvas.getRetinaScaling(),
          );
        });
      }

      this.duloImageEditorService.emitVisibleBoxPaintOccupation({
        paintAreaOccupation: data.paintArea / data.visibleBoxArea,
        visibleBox: {
          area: data.referentSizePrintAreaArea,
          width: data.referentSizePrintAreaWidth,
          height: data.referentSizePrintAreaHeight,
        },
        screenVisibleBox: {
          area: data.visibleBoxArea,
          width: Math.floor(this.rect.width * this.rect.scaleX),
          height: Math.floor(this.rect.height * this.rect.scaleY),
        },
        paint: {
          area:
            (data.paintArea * data.referentSizePrintAreaArea) /
            data.visibleBoxArea,
          borders: data.paintAreaBorders
            ? {
                top:
                  (data.paintAreaBorders.top *
                    data.referentSizePrintAreaHeight) /
                  Math.floor(this.rect.height * this.rect.scaleY),
                bottom:
                  (data.paintAreaBorders.bottom *
                    data.referentSizePrintAreaHeight) /
                  Math.floor(this.rect.height * this.rect.scaleY),
                left:
                  (data.paintAreaBorders.left *
                    data.referentSizePrintAreaWidth) /
                  Math.floor(this.rect.width * this.rect.scaleX),
                right:
                  (data.paintAreaBorders.right *
                    data.referentSizePrintAreaWidth) /
                  Math.floor(this.rect.width * this.rect.scaleX),
              }
            : null,
          width:
            (data.paintWidth * data.referentSizePrintAreaWidth) /
            Math.floor(this.rect.width * this.rect.scaleX),
          height:
            (data.paintHeight * data.referentSizePrintAreaHeight) /
            Math.floor(this.rect.height * this.rect.scaleY),
        },
        screenPaint: {
          area: data.paintArea,
          borders: data.paintAreaBorders,
          width: data.paintWidth,
          height: data.paintHeight,
        },
      });
    };
  }

  ngAfterViewInit(): void {
    this.canvasProperties$.next(this.setCanvasProperties());
    this.setDefaultColor();
    this.initCanvas();

    this.defineFilters();
    this.customizeControls();
  }

  initCanvas(): void {
    this.isCanvasChanged = false;
    const canvasProperties = {
      ...this.canvasProperties$.value,
    };

    this.canvas = new fabric.Canvas(this.canvasEl.nativeElement, {
      canvasProperties,
      imageSmoothingEnabled: false,
      objectCaching: false,
      renderOnAddRemove: false,
    });

    this.canvasWithTransparentObjects = new fabric.Canvas(
      this.canvasWithTransparentObjectsEl.nativeElement,
      {
        canvasProperties,
        imageSmoothingEnabled: false,
        objectCaching: false,
        renderOnAddRemove: false,
      },
    );

    if (this.devEnvironment) {
      this.debugCanvas = new fabric.Canvas(this.debugCanvasEl.nativeElement, {
        canvasProperties,
        imageSmoothingEnabled: false,
        objectCaching: false,
        renderOnAddRemove: false,
      });
    }

    this.objectsLoaded.emit(false);
    const selectedProductPart = { ...this.image };
    this.changeColor(this.colors.filter((c) => c.selected)[0]);
    fabric.Image.fromURL(
      this.image.imageUrl,
      (img): void => {
        if (selectedProductPart.id !== this.image.id) {
          return;
        }
        img.resizeFilter = new fabric.Image.filters.Resize({
          scaleX: this.canvas.width / img.width,
          scaleY: this.canvas.height / img.height,
          resizeType: 'sliceHack',
        });
        img.applyResizeFilters();

        const canvasProperties = this.canvasProperties$.value;

        this.canvas.setBackgroundImage(
          img,
          this.canvas.requestRenderAll.bind(this.canvas),
          {
            scaleX: this.canvas.width / img.width,
            scaleY: this.canvas.height / img.height,
          },
        );

        this.canvasWithTransparentObjects.scaleX =
          this.canvas.width / img.width;
        this.canvasWithTransparentObjects.scaleY =
          this.canvas.height / img.height;

        this.canvas.setDimensions(canvasProperties);
        this.canvasWithTransparentObjects.setDimensions(canvasProperties);

        if (this.devEnvironment) {
          this.debugCanvas.setDimensions(canvasProperties);
        }

        this.onResize();
        this.addEditingBox();
        this.addVisibleEditingBox();
        this.canvas.requestRenderAll();

        if (this.image.objects) {
          this.image.objects.forEach((o) => {
            if (o.id === 'VERTICAL-LINE' || o.id === 'HORIZONTAL-LINE') {
              return;
            }

            o.left =
              ((o.left * this.canvasProperties$.value.width) / o.canvasX) *
              this.canvas.getRetinaScaling();
            o.top =
              ((o.top * this.canvasProperties$.value.height) / o.canvasY) *
              this.canvas.getRetinaScaling();
            o.scaleY =
              ((o.scaleY * this.canvasProperties$.value.height) / o.canvasY) *
              this.canvas.getRetinaScaling();
            o.scaleX =
              ((o.scaleX * this.canvasProperties$.value.height) / o.canvasX) *
              this.canvas.getRetinaScaling();
            o.canvasX = this.canvasProperties$.value.width;
            o.canvasY = this.canvasProperties$.value.height;

            const clone = fabric.util.object.clone(o).set({
              canvas: this.canvasWithTransparentObjects,
              hasBorders: false,
              hasControls: false,
            });
            this.canvasWithTransparentObjects.add(clone);
            this.canvas.add(o);
          });
        } else {
          if (this.objects && this.objects.length > 0) {
            this.loadedObjects = new BehaviorSubject<any>([]);
            this.loadedObjects$ = this.loadedObjects.asObservable();
            this.canvas.on('after:render', (event) => {
              if (this.areObjectsLoaded) {
                this.objectsLoaded.emit(true);
                this.areObjectsLoaded = false;
                this.loaderService.hide();
              }
            });
            this.subs.add(
              this.loadedObjects$
                .pipe(skip(this.objects.length), take(1))
                .subscribe((objects) => {
                  this.loadingObjects = true;

                  objects = objects.sort((a, b) =>
                    a.index > b.index ? 1 : -1,
                  );
                  objects.forEach((o) => {
                    const retinaScalling = this.canvas.getRetinaScaling();
                    o.clipTo = (ctx): void => {
                      ctx.save();
                      ctx.setTransform(
                        retinaScalling,
                        0,
                        0,
                        retinaScalling,
                        0,
                        0,
                      );

                      this.rect.render(ctx);
                      ctx.restore();
                    };

                    this.canvas.add(o);
                    const clone = fabric.util.object.clone(o).set({
                      canvas: this.canvasWithTransparentObjects,
                      hasBorders: false,
                      hasControls: false,
                    });

                    this.canvasWithTransparentObjects.add(clone);
                  });

                  this.areObjectsLoaded = true;

                  this.canvasWithTransparentObjects.requestRenderAll();
                  this.canvas.requestRenderAll();

                  this.onCanvasChanged(false);
                  this.loadingObjects = false;
                }),
            );
            this.areObjectsLoaded = false;
            this.loaderService.show();
            this.objects.forEach((o) => {
              if (o.productObjectTypeItem.productObjectImageUrl) {
                this.addExistingPhoto(o);
              } else {
                this.addExistingText(o);
              }
            });
          } else {
            this.objectsLoaded.emit(true);
          }
        }

        this.onCanvasChanged(false);
        this.setEventHandlers();
      },
      {
        crossOrigin: 'anonymous',
      },
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chosenGalleryImage']?.currentValue) {
      this.uploadPhoto(changes.chosenGalleryImage.currentValue);
    }

    if (changes['image'] && !changes['image']?.firstChange) {
      const objects = this.canvas.getObjects();
      const objectsWithoutBackground =
        this.canvasWithTransparentObjects.getObjects();
      this.unloadingObjects = true;

      objectsWithoutBackground.forEach((o) => {
        this.canvasWithTransparentObjects.remove(o);
      });
      objects.forEach((o) => {
        this.canvas.remove(o);
      });
      this.canvas.dispose();
      this.canvasWithTransparentObjects.dispose();
      this.unloadingObjects = false;

      this.initCanvas();
    }
  }

  ngOnDestroy(): void {
    this.worker.terminate();
  }

  resizeCanvas(width, height): void {
    if (!this.canvas.backgroundImage) {
      return;
    }
    const oldScaleX = this.canvas.backgroundImage.scaleX;
    const oldScaleY = this.canvas.backgroundImage.scaleY;
    this.canvas.backgroundImage.scaleX =
      width / this.canvas.backgroundImage.width;
    this.canvas.backgroundImage.scaleY =
      height / this.canvas.backgroundImage.height;
    const objects = this.canvas.getObjects();
    const objectsWithoutBackground =
      this.canvasWithTransparentObjects.getObjects();
    this.canvas.setDimensions({ width: width, height: height });
    this.canvasWithTransparentObjects.setDimensions({
      width: width,
      height: height,
    });

    if (this.devEnvironment) {
      this.debugCanvas.setDimensions({
        width: width,
        height: height,
      });
    }

    objects.forEach((o) => {
      o.left *= this.canvas.backgroundImage.scaleX / oldScaleX;
      o.top *= this.canvas.backgroundImage.scaleY / oldScaleY;
      o.scaleX *= this.canvas.backgroundImage.scaleX / oldScaleX;
      o.scaleY *= this.canvas.backgroundImage.scaleY / oldScaleY;
      o.canvasX = width;
      o.canvasY = height;
      if (o.id === 'DEFAULT') {
        o.middleX = Math.round(o.left + (o.width * o.scaleX) / 2);
        o.middleY = Math.round(o.top + (o.height * o.scaleY) / 2);
        this.rect = o;
      }
      o.setCoords();
    });

    objectsWithoutBackground.forEach((o) => {
      o.left *= this.canvas.backgroundImage.scaleX / oldScaleX;
      o.top *= this.canvas.backgroundImage.scaleY / oldScaleY;
      o.scaleX *= this.canvas.backgroundImage.scaleX / oldScaleX;
      o.scaleY *= this.canvas.backgroundImage.scaleY / oldScaleY;
      o.canvasX = width;
      o.canvasY = height;
      if (o.id === 'DEFAULT') {
        o.middleX = Math.round(o.left + (o.width * o.scaleX) / 2);
        o.middleY = Math.round(o.top + (o.height * o.scaleY) / 2);
      }
      o.setCoords();
    });

    this.currentImageDPI$.next(this.calculateImageDPI());
  }

  onResize(): void {
    this.canvasProperties$.next(this.setCanvasProperties());

    const canvasProperties = this.canvasProperties$.value;

    if (this.devEnvironment) {
      this.debugCanvas.setDimensions(canvasProperties);
    }

    this.canvasWithTransparentObjects.setDimensions(canvasProperties);
    this.canvas.setDimensions(canvasProperties);

    this.resizeCanvas(
      this.canvasProperties$.value.width,
      this.canvasProperties$.value.width,
    );
  }

  setEventHandlers(): void {
    this.canvas.on('selection:created', (event) => {
      const type: string = event.target.type;
      this.activeObject = event.target;

      if (type === 'image') {
        const src = event.target.getSrc();
        this.setSelected(true, true, false, src.startsWith('data:image/jpeg'));
        this.currentImageDPI$.next(this.calculateImageDPI());
      } else if (type === 'textbox') {
        this.setSelected(true, false, true);
      }

      this.activeObjectOncanvasWithTransparentObjects =
        this.canvasWithTransparentObjects
          .getObjects()
          .filter((o) => o.duloId === event.target.duloId)[0];
      if (this.activeObjectOncanvasWithTransparentObjects) {
        this.activeObjectOncanvasWithTransparentObjects.set({
          ...this.activeObjectOncanvasWithTransparentObjects,
          ...event.target,
        });
      }

      this.canvas.bringToFront(event.target);
      this.canvasWithTransparentObjects.setActiveObject(
        this.activeObjectOncanvasWithTransparentObjects,
      );
      this.canvasWithTransparentObjects.bringToFront(
        this.activeObjectOncanvasWithTransparentObjects,
      );
    });
    this.canvas.on('selection:updated', (event) => {
      const type: string = event.target.type;
      this.activeObject = event.target;
      if (type === 'image') {
        const src = event.target.getSrc();
        this.setSelected(true, true, false, src.startsWith('data:image/jpeg'));
        this.currentImageDPI$.next(this.calculateImageDPI());
      } else if (type === 'textbox') {
        this.setSelected(true, false, true);
      }

      this.activeObjectOncanvasWithTransparentObjects =
        this.canvasWithTransparentObjects
          .getObjects()
          .filter((o) => o.duloId === event.target.duloId)[0];
      if (this.activeObjectOncanvasWithTransparentObjects) {
        this.activeObjectOncanvasWithTransparentObjects.set({
          ...this.activeObjectOncanvasWithTransparentObjects,
          ...event.target,
        });
      }

      this.canvas.bringToFront(event.target);
      this.canvasWithTransparentObjects.setActiveObject(
        this.activeObjectOncanvasWithTransparentObjects,
      );
      this.canvasWithTransparentObjects.bringToFront(
        this.activeObjectOncanvasWithTransparentObjects,
      );
    });
    this.canvas.on('selection:cleared', () => {
      this.activeObject = null;
      this.setSelected(false, false, false);
      this.canvasWithTransparentObjects.discardActiveObject();
      this.activeObjectOncanvasWithTransparentObjects = null;
    });
    this.canvas.on('object:modified', (event) => {
      if (event.target !== null && event.target.id) {
        return;
      }
      this.hideEditingBox();
      this.removeVerticalLine();
      this.removeHorizontalLine();
    });

    this.canvas.on('object:moving', (event) => {
      this.showEditingBox();
      this.resolveVerticalLine(event.target);
      this.resolveHorizontalLine(event.target);

      const updatingObject = this.canvasWithTransparentObjects
        .getObjects()
        .filter((o) => o.duloId === event.target.duloId)[0];
      if (updatingObject) {
        updatingObject.set({
          ...updatingObject,
          ...event.target,
        });
      }
      this.onCanvasChanged(true);
    });
    this.canvas.on('object:rotating', (event) => {
      this.showEditingBox();
      this.resolveVerticalLine(event.target);
      this.resolveHorizontalLine(event.target);

      const updatingObject = this.canvasWithTransparentObjects
        .getObjects()
        .filter((o) => o.duloId === event.target.duloId)[0];
      if (updatingObject) {
        updatingObject.set({
          ...updatingObject,
          ...event.target,
        });
      }
      this.onCanvasChanged(true);
    });
    this.canvas.on('object:scaling', (event) => {
      this.showEditingBox();
      this.currentImageDPI$.next(this.calculateImageDPI());
      this.resolveVerticalLine(event.target);
      this.resolveHorizontalLine(event.target);

      const updatingObject = this.canvasWithTransparentObjects
        .getObjects()
        .filter((o) => o.duloId === event.target.duloId)[0];
      if (updatingObject) {
        updatingObject.set({
          ...updatingObject,
          ...event.target,
        });
      }
      this.onCanvasChanged(true);
    });
    this.canvas.on('object:skewing', (event) => {
      this.showEditingBox();
      this.resolveVerticalLine(event.target);
      this.resolveHorizontalLine(event.target);

      const updatingObject = this.canvasWithTransparentObjects
        .getObjects()
        .filter((o) => o.duloId === event.target.duloId)[0];
      if (updatingObject) {
        updatingObject.set({
          ...updatingObject,
          ...event.target,
        });
      }

      this.onCanvasChanged(true);
    });
    this.canvas.on('object:removed', (event) => {
      if (event.target !== null && event.target.id) {
        return;
      }
      if (!this.unloadingObjects) {
        const removedObject = this.canvasWithTransparentObjects
          .getObjects()
          .filter((o) => o.duloId === event.target.duloId)[0];
        this.canvasWithTransparentObjects.remove(removedObject);

        this.canvasWithTransparentObjects.requestRenderAll();
        this.canvas.requestRenderAll();

        this.onCanvasChanged(true);
      }
    });
    this.canvas.on('object:added', (event) => {
      if (event.target !== null && event.target.id) {
        return;
      }
      if (!this.loadingObjects) {
        this.canvasWithTransparentObjects.requestRenderAll();
        this.canvas.requestRenderAll();

        this.onCanvasChanged(true);
      }
    });
  }

  onCanvasChanged(changed: boolean): void {
    this.calculateVisibleEdditingBoxPaintSubject.next();
    if (changed && !this.isCanvasChanged) {
      this.canvasChanged.emit(true);
      this.isCanvasChanged = true;
    }
  }

  calculateVisibleEdditingBoxPaintOccupation(): void {
    const canvasWithTransparentObjectsContextImageData =
      this.canvasWithTransparentObjectsEl.nativeElement
        .getContext('2d', { willReadFrequently: true })
        .getImageData(
          0,
          0,
          Math.floor(this.canvas.width * this.canvas.getRetinaScaling()),
          Math.floor(this.canvas.height * this.canvas.getRetinaScaling()),
          { colorSpace: 'srgb' },
        );
    const data = {
      canvasWithTransparentObjectsContextImageData: Array.from(
        canvasWithTransparentObjectsContextImageData.data,
      ),
      image: {
        referentSizePrintAreaWidth:
          this.image.partColorImages[0].referentSizePrintAreaWidth,
        referentSizePrintAreaHeight:
          this.image.partColorImages[0].referentSizePrintAreaHeight,
      },
      rect: {
        width: this.rect.width,
        height: this.rect.height,
        top: this.rect.top,
        left: this.rect.left,
        scaleX: this.rect.scaleX,
        scaleY: this.rect.scaleY,
      },
      canvas: {
        width: this.canvas.width,
        height: this.canvas.height,
        retinaScaling: this.canvas.getRetinaScaling(),
      },
    };
    this.worker.postMessage(data);
  }

  setDefaultColor(): void {
    this.colors.filter((c) => c.id === this.defaultColorId)[0].selected = true;
  }

  changeColor(color: Color): void {
    this.colors.forEach((c) => (c.selected = false));
    color.selected = true;
    this.image.imageUrl = this.image.partColorImages.filter(
      (ci) => ci.color.id === color.id,
    )[0].imageUrl;
  }

  changeColorAndBackground(color: Color): void {
    this.changeColor(color);
    const selectedProductPart = { ...this.image };
    fabric.Image.fromURL(
      this.image.imageUrl,
      (img) => {
        if (selectedProductPart.id !== this.image.id) {
          return;
        }
        img.resizeFilter = new fabric.Image.filters.Resize({
          scaleX: this.canvas.width / img.width,
          scaleY: this.canvas.height / img.height,
          resizeType: 'sliceHack',
        });
        img.applyResizeFilters();
        this.canvas.setBackgroundImage(
          img,
          this.canvas.requestRenderAll.bind(this.canvas),
          {
            scaleX: this.canvas.width / img.width,
            scaleY: this.canvas.height / img.height,
          },
        );

        this.canvasWithTransparentObjects.scaleX =
          this.canvas.width / img.width;
        this.canvasWithTransparentObjects.scaleY =
          this.canvas.height / img.height;
      },
      {
        crossOrigin: 'anonymous',
      },
    );
  }

  getObjects(): any {
    return this.canvas
      .getObjects()
      .filter(
        (o) =>
          o.id !== 'DEFAULT' &&
          o.id !== 'VISIBLE-DEFAULT' &&
          o.id !== 'VERTICAL-LINE' &&
          o.id !== 'HORIZONTAL-LINE',
      )
      .map((o, index) => {
        o.index = index;
        return o;
      });
  }

  getObjectsWithoutIndex(): [] {
    return this.canvas
      .getObjects()
      .filter(
        (o) =>
          o.id !== 'DEFAULT' &&
          o.id !== 'VISIBLE-DEFAULT' &&
          o.id !== 'VERTICAL-LINE' &&
          o.id !== 'HORIZONTAL-LINE',
      );
  }

  setCanvasProperties(): any {
    switch (this.screen) {
      case 'desktop': {
        const height =
          +this.canvasWrapper.nativeElement.parentElement.clientHeight;
        const width =
          +this.canvasWrapper.nativeElement.parentElement.clientWidth;
        if (width) {
          return {
            height: Math.min(height, width),
            width: Math.min(height, width),
          };
        } else {
          return {
            height: height,
            width: height,
          };
        }
      }
      case 'mobile': {
        return {
          height: this.canvasWrapper.nativeElement.clientWidth,
          width: this.canvasWrapper.nativeElement.clientWidth,
        };
      }
    }
  }

  addText(): void {
    this.canvas.discardActiveObject();
    const textbox = new fabric.Textbox('', {
      left: this.rect.left + 20,
      top: this.rect.top + 20,
      scaleX: this.canvasProperties$.value.width / this.NODE_CANVAS_SIZE,
      scaleY: this.canvasProperties$.value.height / this.NODE_CANVAS_SIZE,
      editingBorderColor: '#f3364c',
      canvasX: this.canvasProperties$.value.width,
      canvasY: this.canvasProperties$.value.height,
      height: 1000,
      width: 800,
      fontSize:
        Math.floor(
          (2 * this.NODE_CANVAS_SIZE) / this.canvasProperties$.value.height,
        ) * 10,
      text: 'Unesi tekst',
      textAlign: 'center',
      selectionStart: 0,
      opacity: 1,
      fill: '#000000',
      lineHeight: 1.16,
      charSpacing: 28,
      fontWeight: '',
      fontStyle: '',
      fontFamily: 'Times New Roman',
      underline: false,
      overline: false,
      linethrough: false,
      duloId: Math.floor(Math.random() * 1_000_000),
    });
    textbox.selectionEnd = textbox.text.length;
    const retinaScalling = this.canvas.getRetinaScaling();
    textbox.clipTo = (ctx): void => {
      ctx.save();
      ctx.setTransform(retinaScalling, 0, 0, retinaScalling, 0, 0);
      this.rect.render(ctx);
      ctx.restore();
    };

    this.canvas.add(textbox).setActiveObject(textbox);

    textbox.enterEditing();
    this.loadFont();
    this.setSelected(true, false, true);
  }

  addExistingText(text): void {
    const textbox = new fabric.Textbox('', {
      left:
        (text.left * this.canvasProperties$.value.width) /
        this.NODE_CANVAS_SIZE,
      top:
        (text.top * this.canvasProperties$.value.height) /
        this.NODE_CANVAS_SIZE,
      scaleX:
        (text.scaleX * this.canvasProperties$.value.width) /
        this.NODE_CANVAS_SIZE,
      scaleY:
        (text.scaleY * this.canvasProperties$.value.height) /
        this.NODE_CANVAS_SIZE,
      editingBorderColor: '#f3364c',
      canvasX: this.canvasProperties$.value.width,
      canvasY: this.canvasProperties$.value.height,
      height: text.height,
      width: text.width,
      angle: text.angle,
      fontSize: text.product_object_type_item.font_size,
      text: text.product_object_type_item.value,
      textAlign: text.product_object_type_item.text_alignment
        ? text.product_object_type_item.text_alignment
        : '',
      opacity: text.product_object_type_item.opacity,
      fill: text.product_object_type_item.fill,
      lineHeight: text.product_object_type_item.line_height,
      charSpacing: text.product_object_type_item.char_spacing,
      fontWeight: text.product_object_type_item.font_weight
        ? text.product_object_type_item.font_weight
        : '',
      fontStyle: text.product_object_type_item.font_style
        ? text.product_object_type_item.font_style
        : '',
      fontFamily: text.product_object_type_item.font
        ? text.product_object_type_item.font
        : '',
      underline: text.product_object_type_item.underline,
      overline: text.product_object_type_item.overline,
      linethrough: text.product_object_type_item.line_through,
      index: text.index,
    });

    this.loadedObjects.next([...this.loadedObjects.getValue(), textbox]);
  }

  dataURLtoBlob(dataURL: string): Blob {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([arrayBuffer], { type: mimeString });
  }

  uploadPhoto(uploadedImage: BrendlyImage): void {
    this.canvas.discardActiveObject();

    this.showEditingBox();

    this.loaderService.show();

    this.http
      .get(uploadedImage.url, {
        headers: new HttpHeaders().set(SKIP_INTERCEPTORS, SKIP_INTERCEPTORS),
        responseType: 'blob',
        params: {
          updated: moment().format('YYYY-MM-DD-HH-mm-ss'),
        },
      })
      .subscribe((res) => {
        const reader = new FileReader();
        reader.onload = (event: any): void => {
          const originalImg = new Image();
          originalImg.src = event.target.result;

          const CANVAS_SIZE = 4096;

          originalImg.onload = (): void => {
            let url;
            if (Math.max(originalImg.width, originalImg.height) > CANVAS_SIZE) {
              //create canvas
              const canvas = document.createElement('canvas');
              //scale image
              if (originalImg.height >= originalImg.width) {
                canvas.height = CANVAS_SIZE;
                canvas.width =
                  (CANVAS_SIZE / originalImg.height) * originalImg.width;
              } else {
                canvas.width = CANVAS_SIZE;
                canvas.height =
                  (CANVAS_SIZE / originalImg.width) * originalImg.height;
              }
              //draw to canvas
              const context = canvas.getContext('2d');
              context.drawImage(originalImg, 0, 0, canvas.width, canvas.height);
              //assign new image url
              url = context.canvas.toDataURL();
            } else {
              url = URL.createObjectURL(res);
            }

            fabric.Image.fromURL(
              url,
              (img) => {
                this.loaderService.hide();

                const landscape = img.width >= img.height;
                const scale = landscape
                  ? img.width > this.rect.width * this.rect.scaleX
                    ? (((this.rect.width * this.rect.scaleX) / img.width) * 3) /
                      4
                    : Math.min(
                        0.25,
                        (((this.rect.width * this.rect.scaleX) / img.width) *
                          3) /
                          4,
                      )
                  : img.height > this.rect.height * this.rect.scaleY
                  ? (((this.rect.height * this.rect.scaleY) / img.height) * 3) /
                    4
                  : Math.min(
                      0.25,
                      (((this.rect.height * this.rect.scaleY) / img.height) *
                        3) /
                        4,
                    );
                const oImg = img.set({
                  left: this.rect.left,
                  top: this.rect.top,
                  transparentCorners: false,
                  cornerColor: 'black',
                  canvasX: this.canvasProperties$.value.width,
                  canvasY: this.canvasProperties$.value.height,
                  image: res,
                  originalUrl: uploadedImage.url,
                  fileAssetId: uploadedImage.id,
                  duloId: Math.floor(Math.random() * 1_000_000),
                });

                const retinaScalling = this.canvas.getRetinaScaling();
                oImg.clipTo = (ctx): void => {
                  ctx.save();
                  ctx.setTransform(retinaScalling, 0, 0, retinaScalling, 0, 0);
                  this.rect.render(ctx);
                  ctx.restore();
                };
                oImg.scale(scale);
                oImg.resizeFilter = new fabric.Image.filters.Resize({
                  scaleX: scale,
                  scaleY: scale,
                  resizeType: 'sliceHack',
                });

                const clone = fabric.util.object.clone(oImg).set({
                  canvas: this.canvasWithTransparentObjects,
                  hasBorders: false,
                  hasControls: false,
                });

                oImg.applyResizeFilters();
                clone.applyResizeFilters();

                this.canvasWithTransparentObjects
                  .add(clone)
                  .setActiveObject(clone);

                this.canvas.add(oImg).setActiveObject(oImg);
              },
              {
                crossOrigin: 'anonymous',
              },
            );
          };
        };
        reader.readAsDataURL(res);
      });
  }

  addExistingPhoto(image: any): void {
    this.http
      .get(image.productObjectTypeItem.productObjectImageUrl, {
        responseType: 'blob',
        params: {
          updated: moment().format('YYYY-MM-DD-HH-mm-ss'),
        },
      })
      .subscribe((res) => {
        const reader = new FileReader();
        reader.onload = (event: any): void => {
          const originalImg = new Image();
          originalImg.src = event.target.result;

          const CANVAS_SIZE = 4096;

          originalImg.onload = (): void => {
            let url;
            if (Math.max(originalImg.width, originalImg.height) > CANVAS_SIZE) {
              //create canvas
              const canvas = document.createElement('canvas');
              //scale image
              if (originalImg.height >= originalImg.width) {
                canvas.height = CANVAS_SIZE;
                canvas.width =
                  (CANVAS_SIZE / originalImg.height) * originalImg.width;
              } else {
                canvas.width = CANVAS_SIZE;
                canvas.height =
                  (CANVAS_SIZE / originalImg.width) * originalImg.height;
              }
              //draw to canvas
              const context = canvas.getContext('2d');
              context.drawImage(originalImg, 0, 0, canvas.width, canvas.height);
              //assign new image url
              url = context.canvas.toDataURL();
            } else {
              url = URL.createObjectURL(res);
            }

            fabric.Image.fromURL(
              url,
              (img) => {
                const oImg = img.set({
                  left:
                    (image.left * this.canvasProperties$.value.width) /
                    this.NODE_CANVAS_SIZE,
                  top:
                    (image.top * this.canvasProperties$.value.height) /
                    this.NODE_CANVAS_SIZE,
                  scaleX:
                    (image.scaleX * this.canvasProperties$.value.width) /
                    this.NODE_CANVAS_SIZE,
                  scaleY:
                    (image.scaleY * this.canvasProperties$.value.height) /
                    this.NODE_CANVAS_SIZE,
                  height: image.height,
                  width: image.width,
                  angle: image.angle,
                  transparentCorners: false,
                  cornerColor: 'black',
                  index: image.index,
                  canvasX: this.canvasProperties$.value.width,
                  canvasY: this.canvasProperties$.value.height,
                  fileAssetId: image.productObjectTypeItem.fileAssetId,
                  originalUrl:
                    image.productObjectTypeItem.productObjectImageUrl,
                  duloId: Math.floor(Math.random() * 1_000_000),
                });
                for (const f of JSON.parse(
                  image.productObjectTypeItem.filters,
                )) {
                  if (!f) {
                    continue;
                  }
                  if (f.type !== 'Convolute') {
                    oImg.filters[this.filterIndexMap[f.type]] =
                      this.filterMap[this.filterIndexMap[f.type]];
                  } else {
                    if (f.matrix[0] === 0) {
                      oImg.filters[this.filterIndexMap['Convolute-Sharpen']] =
                        this.filterMap[
                          this.filterIndexMap['Convolute-Sharpen']
                        ];
                    } else {
                      oImg.filters[this.filterIndexMap['Convolute-Emboss']] =
                        this.filterMap[this.filterIndexMap['Convolute-Emboss']];
                    }
                  }
                }

                oImg.applyFilters();
                oImg.resizeFilter = new fabric.Image.filters.Resize({
                  scaleX: oImg.scaleX,
                  scaleY: oImg.scaleY,
                  resizeType: 'sliceHack',
                });

                oImg.applyResizeFilters();

                this.loadedObjects.next([
                  ...this.loadedObjects.getValue(),
                  oImg,
                ]);
              },
              {
                crossOrigin: 'anonymous',
              },
            );
          };
        };
        reader.readAsDataURL(res);
      });
  }

  // #region verticalLine and centering
  addVerticalLine(): void {
    const line = new fabric.Line(
      [
        this.rect.middleX,
        0,
        this.rect.middleX,
        this.rect.top + this.rect.height / this.rect.scaleY,
      ],
      {
        stroke: '#47bcc7',
        strokeWidth: 10,
        strokeDashArray: [10, 10],
        selectable: false,
        evented: false,
        id: 'VERTICAL-LINE',
        scaleX: this.canvasProperties$.value.width / this.NODE_CANVAS_SIZE,
        scaleY: this.canvasProperties$.value.height / this.NODE_CANVAS_SIZE,
      },
    );
    const retinaScalling = this.canvas.getRetinaScaling();
    line.clipTo = (ctx): void => {
      ctx.save();
      ctx.setTransform(retinaScalling, 0, 0, retinaScalling, 0, 0);
      this.rect.render(ctx);
      ctx.restore();
    };
    this.verticalLineVisible = true;

    this.canvas.add(line);
  }

  removeVerticalLine(): void {
    const objects = this.canvas.getObjects();
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].id === 'VERTICAL-LINE') {
        this.canvas.remove(objects[i]);
        break;
      }
    }
    this.verticalLineVisible = false;
  }

  resolveVerticalLine(object: any): void {
    const offset =
      (object.width *
        object.scaleX *
        Math.cos((object.angle * 2 * Math.PI) / 360)) /
        2 -
      (object.height *
        object.scaleY *
        Math.sin((object.angle * 2 * Math.PI) / 360)) /
        2;
    const middle = Math.round(object.left + offset);
    const epsilonNeighborhood = 10;
    if (
      middle >= this.rect.middleX - epsilonNeighborhood &&
      middle <= this.rect.middleX + epsilonNeighborhood
    ) {
      this.centerObject(object, offset, 'vertical');
      if (!this.verticalLineVisible) {
        this.addVerticalLine();
      }
    } else {
      if (this.verticalLineVisible) {
        this.removeVerticalLine();
      }
    }
  }

  addHorizontalLine(): void {
    const line = new fabric.Line(
      [
        0,
        this.rect.middleY,
        this.rect.left + this.rect.width / this.rect.scaleX,
        this.rect.middleY,
      ],
      {
        stroke: '#47bcc7',
        strokeWidth: 10,
        strokeDashArray: [10, 10],
        selectable: false,
        evented: false,
        id: 'HORIZONTAL-LINE',
        scaleX: this.canvasProperties$.value.width / this.NODE_CANVAS_SIZE,
        scaleY: this.canvasProperties$.value.height / this.NODE_CANVAS_SIZE,
      },
    );
    const retinaScalling = this.canvas.getRetinaScaling();
    line.clipTo = (ctx): void => {
      ctx.save();
      ctx.setTransform(retinaScalling, 0, 0, retinaScalling, 0, 0);
      this.rect.render(ctx);
      ctx.restore();
    };
    this.horizontalLineVisible = true;

    this.canvas.add(line);
  }

  removeHorizontalLine(): void {
    const objects = this.canvas.getObjects();
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].id === 'HORIZONTAL-LINE') {
        this.canvas.remove(objects[i]);
        break;
      }
    }
    this.horizontalLineVisible = false;
  }

  resolveHorizontalLine(object: any): void {
    const offset =
      (object.height *
        object.scaleY *
        Math.cos((object.angle * 2 * Math.PI) / 360)) /
        2 -
      (object.width *
        object.scaleX *
        Math.sin((object.angle * 2 * Math.PI) / 360)) /
        2;
    const middle = Math.round(object.top + offset);
    const epsilonNeighborhood = 10;
    if (
      middle >= this.rect.middleY - epsilonNeighborhood &&
      middle <= this.rect.middleY + epsilonNeighborhood
    ) {
      this.centerObject(object, offset, 'horizontal');
      if (!this.horizontalLineVisible) {
        this.addHorizontalLine();
      }
    } else {
      if (this.horizontalLineVisible) {
        this.removeHorizontalLine();
      }
    }
  }

  centerObject(
    object: any,
    offset: number,
    type: 'horizontal' | 'vertical',
  ): void {
    if (type === 'vertical') {
      object.set('left', this.rect.middleX - offset);
    } else {
      object.set('top', this.rect.middleY - offset);
    }
    object.setCoords();
  }
  // #endregion
  // #region editingBox
  addEditingBox(): void {
    // fabric.loadSVGFromURL('assets/spomenik.svg', (objects, options) => {
    //   this.rect = new fabric.Path(objects[0].d, {});
    //   // this.rect = fabric.util.groupSVGElements(objects, options);

    //   const x =
    //     (this.image.width * this.canvasProperties$.value.width) /
    //     this.NODE_CANVAS_SIZE;
    //   const y =
    //     (this.image.height * this.canvasProperties$.value.height) /
    //     this.NODE_CANVAS_SIZE;

    //   (this.rect.left =
    //     (this.image.left * this.canvasProperties$.value.width) /
    //       this.NODE_CANVAS_SIZE +
    //     (x * (1 - this.image.scaleX)) / 2),
    //     (this.rect.top =
    //       (this.image.top * this.canvasProperties$.value.width) /
    //         this.NODE_CANVAS_SIZE +
    //       (y * (1 - this.image.scaleY)) / 2),
    //     (this.rect.opacity = 1);
    //   this.rect.scaleX =
    //     (this.canvasProperties$.value.width / this.NODE_CANVAS_SIZE) *
    //     this.image.scaleX;
    //   this.rect.scaleY =
    //     (this.canvasProperties$.value.height / this.NODE_CANVAS_SIZE) *
    //     this.image.scaleY;
    //   this.rect.fill = this.backgroundColor;
    //   this.rect.backgroundColor = this.backgroundColor;
    //   this.rect.width = this.image.width;
    //   this.rect.height = this.image.height;
    //   // this.rect.selectable = false;
    //   this.rect.id = 'DEFAULT';
    //   this.rect.duloId = 'DEFAULT';
    //   this.rect.preserveObjectStacking = true;
    //   this.rect.objectCaching = false;
    //   this.rect.middleX = Math.round(
    //     this.rect.left + (this.rect.width * this.rect.scaleX) / 2,
    //   );
    //   this.rect.middleY = Math.round(
    //     this.rect.top + (this.rect.height * this.rect.scaleY) / 2,
    //   );

    //   const retinaScalling = this.canvas.getRetinaScaling();
    //   this.rect.clipTo = (ctx): void => {
    //     ctx.save();
    //     ctx.setTransform(retinaScalling, 0, 0, retinaScalling, 0, 0);
    //     ctx.restore();
    //   };

    //   const clone = fabric.util.object.clone(this.rect).set({
    //     opacity: 0,
    //     canvas: this.canvasWithTransparentObjects,
    //   });

    //   this.canvasWithTransparentObjects.add(clone);
    //   this.canvasWithTransparentObjects.sendToBack(clone);

    //   this.canvas.add(this.rect);
    //   this.canvas.sendToBack(this.rect);
    // });

    const x =
      (this.image.width * this.canvasProperties$.value.width) /
      this.NODE_CANVAS_SIZE;
    const y =
      (this.image.height * this.canvasProperties$.value.height) /
      this.NODE_CANVAS_SIZE;
    this.rect = new fabric.Rect({
      left:
        (this.image.left * this.canvasProperties$.value.width) /
          this.NODE_CANVAS_SIZE +
        (x * (1 - this.image.scaleX)) / 2,
      top:
        (this.image.top * this.canvasProperties$.value.width) /
          this.NODE_CANVAS_SIZE +
        (y * (1 - this.image.scaleY)) / 2,
      opacity: 1,
      scaleX:
        (this.canvasProperties$.value.width / this.NODE_CANVAS_SIZE) *
        this.image.scaleX,
      scaleY:
        (this.canvasProperties$.value.height / this.NODE_CANVAS_SIZE) *
        this.image.scaleY,
      fill: this.backgroundColor,
      backgroundColor: this.backgroundColor,
      width: this.image.width,
      height: this.image.height,
      selectable: false,
      id: 'DEFAULT',
      duloId: 'DEFAULT',
      preserveObjectStacking: true,
      objectCaching: false,
      middleX: 0,
      middleY: 0,
    });
    this.rect.middleX = Math.round(
      this.rect.left + (this.rect.width * this.rect.scaleX) / 2,
    );
    this.rect.middleY = Math.round(
      this.rect.top + (this.rect.height * this.rect.scaleY) / 2,
    );

    const clone = fabric.util.object.clone(this.rect).set({
      opacity: 0,
      canvas: this.canvasWithTransparentObjects,
    });

    this.canvasWithTransparentObjects.add(clone);
    this.canvasWithTransparentObjects.sendToBack(clone);

    this.canvas.add(this.rect);
    this.canvas.sendToBack(this.rect);
  }

  addVisibleEditingBox(): void {
    const x =
      (this.image.width * this.canvasProperties$.value.width) /
      this.NODE_CANVAS_SIZE;
    const y =
      (this.image.height * this.canvasProperties$.value.height) /
      this.NODE_CANVAS_SIZE;
    this.visibleEditingBox = new fabric.Rect({
      left:
        (this.image.left * this.canvasProperties$.value.width) /
          this.NODE_CANVAS_SIZE +
        (x * (1 - this.image.scaleX)) / 2,
      top:
        (this.image.top * this.canvasProperties$.value.width) /
          this.NODE_CANVAS_SIZE +
        (y * (1 - this.image.scaleY)) / 2,
      stroke: '#47bcc7',
      strokeWidth: 0,
      strokeDashArray: [10, 10],
      opacity: 1,
      scaleX:
        (this.canvasProperties$.value.width / this.NODE_CANVAS_SIZE) *
        this.image.scaleX,
      scaleY:
        (this.canvasProperties$.value.height / this.NODE_CANVAS_SIZE) *
        this.image.scaleY,
      fill: this.backgroundColor,
      backgroundColor: this.backgroundColor,
      width: this.image.width,
      height: this.image.height,
      selectable: false,
      id: 'VISIBLE-DEFAULT',
      duloId: 'VISIBLE-DEFAULT',
      preserveObjectStacking: true,
      objectCaching: false,
      middleX: 0,
      middleY: 0,
    });

    // fabric.loadSVGFromURL('assets/spomenik.svg', (objects, options) => {
    //   this.visibleEditingBox = new fabric.Path(objects[0].d, {});
    //   // this.visibleEditingBox = fabric.util.groupSVGElements(objects, options);

    //   (this.visibleEditingBox.left =
    //     (this.image.left * this.canvasProperties$.value.width) /
    //       this.NODE_CANVAS_SIZE +
    //     (x * (1 - this.image.scaleX)) / 2),
    //     (this.visibleEditingBox.top =
    //       (this.image.top * this.canvasProperties$.value.width) /
    //         this.NODE_CANVAS_SIZE +
    //       (y * (1 - this.image.scaleY)) / 2),
    //     (this.visibleEditingBox.opacity = 1);

    //   this.visibleEditingBox.scaleX =
    //     (this.canvasProperties$.value.width / this.NODE_CANVAS_SIZE) *
    //     this.image.scaleX;

    //   (this.visibleEditingBox.scaleY =
    //     (this.canvasProperties$.value.height / this.NODE_CANVAS_SIZE) *
    //     this.image.scaleY),
    //     (this.visibleEditingBox.fill = this.backgroundColor),
    //     (this.visibleEditingBox.backgroundColor = this.backgroundColor),
    //     (this.visibleEditingBox.width = this.image.width),
    //     (this.visibleEditingBox.height = this.image.height),
    //     // (this.visibleEditingBox.selectable = false),
    //     (this.visibleEditingBox.id = 'VISIBLE-DEFAULT'),
    //     (this.visibleEditingBox.duloId = 'VISIBLE-DEFAULT'),
    //     (this.visibleEditingBox.preserveObjectStacking = true),
    //     (this.visibleEditingBox.objectCaching = false),
    //     (this.visibleEditingBox.middleX = Math.round(
    //       this.visibleEditingBox.left +
    //         (this.visibleEditingBox.width * this.visibleEditingBox.scaleX) / 2,
    //     ));
    //   this.visibleEditingBox.middleY = Math.round(
    //     this.visibleEditingBox.top +
    //       (this.visibleEditingBox.height * this.visibleEditingBox.scaleY) / 2,
    //   );

    //   this.canvas.add(this.visibleEditingBox);
    //   this.canvas.sendToBack(this.visibleEditingBox);
    // });

    this.visibleEditingBox.middleX = Math.round(
      this.visibleEditingBox.left +
        (this.visibleEditingBox.width * this.visibleEditingBox.scaleX) / 2,
    );
    this.visibleEditingBox.middleY = Math.round(
      this.visibleEditingBox.top +
        (this.visibleEditingBox.height * this.visibleEditingBox.scaleY) / 2,
    );

    const clone = fabric.util.object.clone(this.visibleEditingBox).set({
      canvas: this.canvasWithTransparentObjects,
    });

    this.canvasWithTransparentObjects.add(clone);
    this.canvasWithTransparentObjects.sendToBack(clone);

    this.canvas.add(this.visibleEditingBox);
    this.canvas.sendToBack(this.visibleEditingBox);
  }

  showEditingBox(): void {
    this.visibleEditingBox.fill = 'rgba(0,0,0,0.05)';
    this.visibleEditingBox.strokeWidth = 1 / this.visibleEditingBox.scaleX;
  }

  hideEditingBox(): void {
    this.visibleEditingBox.fill = 'transparent';
    this.visibleEditingBox.strokeWidth = 0;
  }

  setBackgroundColor(): void {
    this.image.backgroundColor = this.visibleEditingBox.backgroundColor;
    this.visibleEditingBox.fill = this.visibleEditingBox.backgroundColor;

    const visibleEditingBoxOnCanvasWIthTransparentObjects =
      this.canvasWithTransparentObjects
        .getObjects()
        .filter((o) => o.duloId === this.visibleEditingBox.duloId)[0];

    visibleEditingBoxOnCanvasWIthTransparentObjects.backgroundColor =
      this.visibleEditingBox.backgroundColor;
    visibleEditingBoxOnCanvasWIthTransparentObjects.fill =
      this.visibleEditingBox.backgroundColor;

    this.canvasWithTransparentObjects.requestRenderAll();
    this.canvas.requestRenderAll();
    this.onCanvasChanged(true);
  }
  // #endregion
  // #region filters and controls
  setSelected(
    selected: boolean,
    imageSelected: boolean,
    textSelected: boolean,
    isImageJPG = false,
  ): void {
    this.imageSelected = imageSelected;
    this.textSelected = textSelected;
    this.isImageJPG = isImageJPG;
    this.selected$.next(selected);
  }

  toggleFilter(checked, index): void {
    if (checked) {
      this.applyFilter(index, this.filterMap[index]);
    } else {
      this.applyFilter(index, false);
    }
  }

  applyFilter(index, filter): void {
    const object = this.canvas.getActiveObject();
    object.filters[index] = filter;
    object.applyFilters();
  }

  closeFilters(): void {
    this.canvas.discardActiveObject();
  }

  defineFilters(): void {
    this.filterMap[0] = new fabric.Image.filters.Grayscale();
    this.filterIndexMap['Grayscale'] = 0;
    this.filterMap[1] = new fabric.Image.filters.Invert();
    this.filterIndexMap['Invert'] = 1;
    this.filterMap[2] = new fabric.Image.filters.RemoveColor({
      distance: '',
      color: '',
    });
    this.filterIndexMap['RemoveColor'] = 2;
    this.filterMap[3] = new fabric.Image.filters.Sepia();
    this.filterIndexMap['Sepia'] = 3;
    this.filterMap[4] = new fabric.Image.filters.Brownie();
    this.filterIndexMap['Brownie'] = 4;
    this.filterMap[5] = new fabric.Image.filters.Brightness({});
    this.filterIndexMap['Brightness'] = 5;
    this.filterMap[6] = new fabric.Image.filters.Contrast({
      constrast: '',
    });
    this.filterIndexMap['Contrast'] = 6;
    this.filterMap[7] = new fabric.Image.filters.Saturation({});
    this.filterIndexMap['Saturation'] = 7;
    this.filterMap[8] = new fabric.Image.filters.Noise({
      noise: '',
    });
    this.filterIndexMap['Noise'] = 8;
    this.filterMap[9] = new fabric.Image.filters.Vintage();
    this.filterIndexMap['Vintage'] = 9;
    this.filterMap[10] = new fabric.Image.filters.Pixelate({
      blocksize: '',
    });
    this.filterIndexMap['Pixelate'] = 10;
    this.filterMap[11] = new fabric.Image.filters.Blur({
      value: '',
    });
    this.filterIndexMap['Blur'] = 11;
    this.filterMap[12] = new fabric.Image.filters.Convolute({
      matrix: [0, -1, 0, -1, 5, -1, 0, -1, 0],
    });
    this.filterIndexMap['Convolute-Sharpen'] = 12;
    this.filterMap[13] = new fabric.Image.filters.Convolute({
      matrix: [1, 1, 1, 1, 0.7, -1, -1, -1, -1],
    });
    this.filterIndexMap['Convolute-Emboss'] = 13;
    this.filterMap[14] = new fabric.Image.filters.Technicolor();
    this.filterIndexMap['Technicolor'] = 14;
    this.filterMap[15] = new fabric.Image.filters.Polaroid();
    this.filterIndexMap['Polaroid'] = 15;
    this.filterMap[16] = new fabric.Image.filters.BlendColor({
      color: '',
      mode: '',
      alpha: '',
    });
    this.filterIndexMap['BlendColor'] = 16;
    this.filterMap[17] = new fabric.Image.filters.Gamma({
      gamma: ['', '', ''],
    });
    this.filterIndexMap['Gamma'] = 17;
    this.filterMap[18] = new fabric.Image.filters.Kodachrome();
    this.filterIndexMap['Kodachrome'] = 18;
    this.filterMap[19] = new fabric.Image.filters.BlackWhite();
    this.filterIndexMap['BlackWhite'] = 19;
    this.filterMap[20] = new fabric.Image.filters.BlendImage({
      image: '',
    });
    this.filterIndexMap['BlendImage'] = 20;
    this.filterMap[21] = new fabric.Image.filters.HueRotation({
      rotation: '',
    });
    this.filterIndexMap['HueRotation'] = 21;
  }

  customizeControls(): void {
    fabric.Canvas.prototype.customiseControls(
      {
        tr: {
          action: 'rotate',
          cursor: 'pointer',
        },
        br: {
          action: 'scale',
        },
        bl: {
          action: 'remove',
          cursor: 'pointer',
        },
      },
      () => {},
    );
    fabric.Object.prototype.customiseCornerIcons(
      {
        settings: {
          borderColor: 'black',
          cornerSize: 25,
          cornerShape: 'circle',
          cornerBackgroundColor: '#f3364c',
          cornerPadding: 10,
        },
        tr: {
          icon: 'assets/editor-icons/rotate.svg',
        },
        br: {
          icon: 'assets/editor-icons/resize.svg',
        },
        bl: {
          icon: 'assets/editor-icons/remove.svg',
        },
      },
      () => {},
    );
    fabric.Object.prototype.setControlsVisibility({
      mt: false,
      mb: false,
      ms: false,
      mr: false,
      ml: false,
      bl: true,
      br: true,
      tl: false,
      tr: true,
      mtr: false,
    });
  }

  loadFont(): void {
    const canvasFont = new FontFaceObserver(this.activeObject.fontFamily);

    canvasFont
      .load()
      .then(() => {
        this.setFontFamily();
      })
      .catch(function (e) {});
  }

  calculateImageDPI(): number {
    const image = this.canvas.getActiveObject();
    if (!image) {
      return 0;
    }
    const imageWidth = image.width;
    const currentImageWidth = image.width * image.scaleX;
    const canvasWidth = this.canvasProperties$.value.width;

    const proportion =
      (this.image.width * this.image.scaleX) / this.NODE_CANVAS_SIZE;
    const rectWidth = canvasWidth * proportion;

    const imageInches = ((30 / 2.54) * currentImageWidth) / rectWidth;

    const dpi = imageWidth / imageInches;
    this.currentImageDPIState =
      dpi > 85 ? (dpi > 135 ? 'Dobar' : 'Prosečan') : 'Loš';
    return Math.round(dpi);
  }

  triggerCanvasChange(): void {
    this.canvas.requestRenderAll();
    this.canvas.fire('object:modified', { target: this.activeObject });
  }

  alignVertical(): number {
    const image = this.canvas.getActiveObject();
    if (!image) {
      return 0;
    }

    image.top =
      this.visibleEditingBox.middleY - (image.height * image.scaleY) / 2;
    image.setCoords();

    this.triggerCanvasChange();
  }

  alignHorizontal(): number {
    const image = this.canvas.getActiveObject();
    if (!image) {
      return 0;
    }

    image.left =
      this.visibleEditingBox.middleX - (image.width * image.scaleX) / 2;
    image.setCoords();

    this.triggerCanvasChange();
  }

  alignLeft(): number {
    const image = this.canvas.getActiveObject();
    if (!image) {
      return 0;
    }

    const safeArea = 4 * this.visibleEditingBox.scaleX;

    image.left = this.visibleEditingBox.left + safeArea;
    image.setCoords();

    this.triggerCanvasChange();
  }

  alignTop(): number {
    const image = this.canvas.getActiveObject();
    if (!image) {
      return 0;
    }

    const safeArea = 4 * this.visibleEditingBox.scaleY;

    image.top = this.visibleEditingBox.top + safeArea;
    image.setCoords();

    this.triggerCanvasChange();
  }

  alignRight(): number {
    const image = this.canvas.getActiveObject();
    if (!image) {
      return 0;
    }

    const safeArea = 4 * this.visibleEditingBox.scaleX;

    image.left =
      this.visibleEditingBox.left +
      this.visibleEditingBox.width * this.visibleEditingBox.scaleX -
      image.width * image.scaleX -
      safeArea;
    image.setCoords();

    this.triggerCanvasChange();
  }

  alignBottom(): number {
    const image = this.canvas.getActiveObject();
    if (!image) {
      return 0;
    }

    const safeArea = 4 * this.visibleEditingBox.scaleY;

    image.top =
      this.visibleEditingBox.top +
      this.visibleEditingBox.height * this.visibleEditingBox.scaleY -
      image.height * image.scaleY -
      safeArea;
    image.setCoords();

    this.triggerCanvasChange();
  }

  expand(): number {
    const image = this.canvas.getActiveObject();
    if (!image) {
      return 0;
    }

    const safeAreaX = 4 * this.visibleEditingBox.scaleX;
    const safeAreaY = 4 * this.visibleEditingBox.scaleY;

    const k = Math.min(
      (this.rect.width * this.rect.scaleX - 2 * safeAreaX) /
        (image.width * image.scaleX),
      (this.rect.height * this.rect.scaleY - 2 * safeAreaY) /
        (image.height * image.scaleY),
    );

    image.scale(image.scaleX * k);
    image.left =
      this.rect.left +
      (this.rect.width * this.rect.scaleX - image.width * image.scaleX) / 2;
    image.top =
      this.rect.top +
      (this.rect.height * this.rect.scaleY - image.height * image.scaleY) / 2;
    image.resizeFilter = new fabric.Image.filters.Resize({
      scaleX: image.scaleX * k,
      scaleY: image.scaleX * k,
      resizeType: 'sliceHack',
    });
    image.applyResizeFilters();
    image.setCoords();

    this.currentImageDPI$.next(this.calculateImageDPI());

    this.triggerCanvasChange();
  }
  // #endregion
  // #region textEditing
  setActiveStyle(styleName, value, object): void {
    object = object || this.canvas.getActiveObject();
    if (!object) {
      return;
    }
    if (object.selectionEnd === object.selectionStart) {
      object.selectionStart = 0;
      object.selectionEnd = object.text.length;
    }
    const style = {};
    style[styleName] = value;
    object.setSelectionStyles(style);
    object.setCoords();
  }

  setActiveProp(name, value): void {
    const object = this.canvas.getActiveObject();
    if (!object) {
      return;
    }
    object.set(name, value).setCoords();
  }

  setTextAlign(align: string): void {
    this.activeObject.textAlign = align;
    this.setActiveStyle('textAlign', align, null);
  }

  setOpacity(): void {
    this.setActiveProp('opacity', this.activeObject.opacity);
  }

  setFill(): void {
    this.setActiveStyle('fill', this.activeObject.fill, null);
  }

  setLineHeight(): void {
    this.setActiveStyle('lineHeight', this.activeObject.lineHeight, null);
  }

  setCharSpacing(): void {
    this.setActiveStyle('charSpacing', this.activeObject.charSpacing, null);
  }

  setFontSize(): void {
    this.setActiveStyle('fontSize', this.activeObject.fontSize, null);
  }

  setBold(): void {
    this.activeObject.fontWeight =
      this.activeObject.fontWeight === '' ? 'bold' : '';
    this.setActiveStyle('fontWeight', this.activeObject.fontWeight, null);
  }

  setFontStyle(): void {
    this.activeObject.fontStyle =
      this.activeObject.fontStyle === '' ? 'italic' : '';
    this.setActiveStyle('fontStyle', this.activeObject.fontStyle, null);
  }

  setUnderline(): void {
    this.activeObject.underline = !this.activeObject.underline;
    this.setActiveStyle('underline', this.activeObject.underline, null);
  }

  setOverline(): void {
    this.activeObject.overline = !this.activeObject.overline;
    this.setActiveStyle('overline', this.activeObject.overline, null);
  }

  setLineThrough(): void {
    this.activeObject.linethrough = !this.activeObject.linethrough;
    this.setActiveStyle('linethrough', this.activeObject.linethrough, null);
  }

  setFontFamily(): void {
    const object = this.canvas.getActiveObject();
    this.canvas.discardActiveObject();
    this.canvas.setActiveObject(object);
  }
  // #endregion
}
