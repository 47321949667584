import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  OnDestroy,
  EventEmitter,
  Output,
  Input,
  SimpleChanges,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { KaduFormComponent } from '@kadung/kadung/kadu-form';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-found-brendly',
  templateUrl: './found-brendly.component.html',
  styleUrls: ['./found-brendly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoundBrendlyComponent implements OnDestroy {
  @ViewChild(KaduFormComponent, { static: false })
  formComponent: KaduFormComponent;
  @Input() userForRegistration$: BehaviorSubject<any>;

  @Output('previousStep') previousStep = new EventEmitter<any>();
  @Output('save') save = new EventEmitter<any>();

  formControls = { refererOrigin: { state: 'recommendation' } };
  subs = new SubSink();

  options = [
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.recommendation',
      ),
      value: 'recommendation',
    },
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.recommendation',
      ),
      value: 'facebook',
    },
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.instagram',
      ),
      value: 'instagram',
    },
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.googleAds',
      ),
      value: 'googleAds',
    },
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.google',
      ),
      value: 'google',
    },
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.youtube',
      ),
      value: 'youtube',
    },
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.tiktok',
      ),
      value: 'tiktok',
    },
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.options.other',
      ),
      value: 'other',
    },
  ];

  constructor(private translateService: TranslocoService) {}

  ngAfterViewInit(): void {
    this.subs.add(
      this.formComponent.formGroup
        .get('refererOrigin')
        .valueChanges.subscribe((value) => {
          if (value === 'other') {
            this.formComponent.addControlToForm('refererOriginFreeEntry', {
              state: '',
              validators: [Validators.required],
            });
          } else {
            this.formComponent.removeControlFromForm('refererOriginFreeEntry');
          }
        }),
    );

    if (this.userForRegistration$.value.refererOrigin) {
      this.formComponent.formGroup.patchValue(
        this.userForRegistration$.value.refererOrigin,
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const userForRegistration = changes.userForRegistration$.currentValue.value;

    if (userForRegistration.refererOrigin) {
      setTimeout(() => {
        if (userForRegistration.refererOriginType === 'other') {
          this.formComponent.formGroup.patchValue({
            refererOrigin: userForRegistration.refererOriginType,
            refererOriginFreeEntry: userForRegistration.refererOrigin,
          });
        } else {
          this.formComponent.formGroup.patchValue({
            refererOrigin: userForRegistration.refererOrigin,
          });
        }
      });
    }
  }

  goToStep(mode: 'prev' | 'next') {
    this.formComponent.submit();

    if (this.formComponent.formGroup.valid) {
      this.userForRegistration$.next({
        ...this.userForRegistration$.value,
        refererOriginType: this.formComponent.formGroup.value.refererOrigin,
        refererOrigin:
          this.formComponent.formGroup.value.refererOriginFreeEntry ||
          this.formComponent.formGroup.value.refererOrigin,
      });

      if (mode === 'prev') {
        this.previousStep.emit();
      } else {
        this.save.emit();
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
