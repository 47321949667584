import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { KaduFormComponent } from '@kadung/kadung/kadu-form';
import { TranslocoService } from '@ngneat/transloco';
import { DialogService } from 'primeng/dynamicdialog';
import { Country } from 'src/app/_models/country';
import { LoginResponse } from 'src/app/_models/loginResponse';
import { UserLogin } from 'src/app/_models/userLogin';
import { UserSignup } from 'src/app/_models/userSignup';
import { AppService } from 'src/app/_services/app/app.service';
import { PopupService } from 'src/app/_services/popup/popup.service';
import { UserService } from 'src/app/dashboard/_services/user/user.service';
import { emailREG, passwordsMatchValidator } from 'src/app/shared/util';
import { SubSink } from 'subsink';
import { AuthService } from '../../../_services/auth/auth.service';
import { KaduFormRequest } from '@kadung/kadung/kadu-form';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class SignupComponent
  extends KaduFormRequest
  implements OnInit, OnDestroy
{
  @ViewChild(KaduFormComponent, { static: false })
  formComponent: KaduFormComponent;
  steps = [
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.aboutUser',
      ),
    },
    {
      label: this.translateService.translate(
        'dashboard.signupSurvey.howUserFoundBrendly',
      ),
    },
  ];
  selectedIndex$ = new BehaviorSubject<number>(-1);
  userForRegistration$ = new BehaviorSubject<any>(null);

  countries = this.appService.countries.filter((c) => c.signupAvailable);
  selectedCountry = this.appService.country$.value;
  formControls = {
    email: {
      state: '',
      validators: [Validators.required, Validators.pattern(emailREG)],
    },
    password: {
      state: '',
      validators: [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/,
        ),
      ],
    },
    passwordAgain: { state: '', validators: [Validators.required] },
    firstName: { state: '', validators: [Validators.required] },
    lastName: { state: '', validators: [Validators.required] },
    phoneNumber: { state: '', validators: [Validators.required] },
    termsAndConditions: { state: false, validators: [Validators.requiredTrue] },
    defaultCountry: { state: this.selectedCountry },
    defaultLanguage: { state: this.selectedCountry.alpha2Code },
  };

  options = {
    validators: [passwordsMatchValidator],
  };

  subs = new SubSink();
  constructor(
    private authService: AuthService,
    private router: Router,
    public dialogService: DialogService,
    public translateService: TranslocoService,
    private userService: UserService,
    private appService: AppService,
    private popupService: PopupService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.authService.isUserLoggedIn()) {
      this.router.navigate(['dashboard']);
    }
  }

  ngAfterViewInit(): void {
    this.subs.add(
      this.formComponent.formGroup
        .get('email')
        .valueChanges.pipe(distinctUntilChanged(), debounceTime(1000))
        .subscribe((email) => {
          this.userService.doesEmailExists(email).subscribe((response) => {
            if (response.emailExists) {
              this.formComponent.formGroup
                .get('email')
                .setErrors({ emailExists: true });
            }
          });
        }),
      combineLatest([this.userForRegistration$, this.selectedIndex$]).subscribe(
        ([userForRegistration, selectedIndex]) => {
          if (userForRegistration && selectedIndex === -1) {
            setTimeout(() => {
              this.formComponent.formGroup.patchValue(userForRegistration);
            });
          }
        },
      ),
    );
  }

  goToSteps(formValue: any): void {
    this.formComponent.submit();

    if (this.formComponent.formGroup.valid) {
      this.selectedIndex$.next(0);
      this.userForRegistration$.next({
        ...this.userForRegistration$.value,
        ...formValue,
      });
    }
  }

  goToPage(step: number) {
    this.selectedIndex$.next(step);
  }

  selectCountry(country: Country): void {
    this.appService.country = country;

    this.formComponent.formGroup
      .get('defaultLanguage')
      .setValue(country.alpha2Code);
  }

  onSignUp(): void {
    const userForRegistration = new UserSignup({
      email: this.userForRegistration$.value.email,
      password: this.userForRegistration$.value.password,
      firstName: this.userForRegistration$.value.firstName,
      lastName: this.userForRegistration$.value.lastName,
      phoneNumber: this.userForRegistration$.value.phoneNumber,
      defaultCountry: this.userForRegistration$.value.defaultCountry.name,
      defaultLanguage: this.userForRegistration$.value.defaultLanguage,
      country: this.userForRegistration$.value.defaultCountry.name,
      customerSegment: this.userForRegistration$.value.customerSegment,
      refererOrigin: this.userForRegistration$.value.refererOrigin,
    });
    this.getRequest(userForRegistration).subscribe(() => {
      this.popupService
        .fire({
          titleText: this.translateService.translate('auth.signup.signedUp'),
          type: 'success',
          confirmButtonColor: '#f3364c',
        })
        .then(() => {
          const user = new UserLogin({
            email: userForRegistration.email,
            password: userForRegistration.password,
          });
          this.authService
            .logIn(user)
            .subscribe((loginResponse: LoginResponse) => {
              this.authService.saveAccessTokenToLocalStorage(
                loginResponse.accessToken,
              );
              this.authService.saveRefreshTokenToLocalStorage(
                loginResponse.refreshToken,
              );

              this.userService
                .sendVerificationEmail(this.authService.getUserId())
                .subscribe();

              this.router.navigate(['dashboard']);
            });
        });
    });
  }

  protected _getRequest(userForRegistration: UserSignup): Observable<any> {
    return this.userService.signUp(userForRegistration);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
